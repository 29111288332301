import {CustomerDetailsOpen} from './Components/CustomerDetailsOpen';
import {CustomerDetailsCollapsed} from './Components/CustomerDetailsCollapsed';
import React from 'react';
import {Step} from '../../StepsManager/Components/Step';
import {useLocaleKeys} from '../../../../../locale-keys/LocaleKeys';
import {StepImplementationProps} from '../../../../../types/app.types';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {withCustomerDetailsData} from './WithCustomerDetailsData/WithCustomerDetailsData';
import {withMemberDetailsData} from '../../../MemberDetails/WithMemberDetailsData';

export enum CustomerDetailsStepDataHook {
  root = 'CustomerDetailsStep.root',
  collapsed = 'CustomerDetailsStep.collapsed',
  open = 'CustomerDetailsStep.open',
  empty = 'CustomerDetailsStep.empty',
}

export const CustomerDetailsStep = ({index}: StepImplementationProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {isShippingFlow},
  } = useControllerProps();

  return (
    <Step
      index={index!}
      title={isShippingFlow ? localeKeys.checkout.delivery_details_title() : localeKeys.checkout.customer_details()}
      dataHook={CustomerDetailsStepDataHook.root}
      openComponent={WrappedCustomerDetailsOpenStep}
      collapsedComponent={CustomerDetailsCollapsed}
    />
  );
};

const WrappedCustomerDetailsOpenStep = withMemberDetailsData(
  withCustomerDetailsData(CustomerDetailsOpen),
  (checkout) => checkout.shippingDestination,
  'validateShippingAddress'
);
