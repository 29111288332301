import React, {useEffect, useState} from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {DeliveryMethodGroup} from './DeliveryMethodGroup';
import {DeliveryMethod} from './DeliveryMethod';
import {ScheduledDeliverySelector} from './ScheduledDeliverySelector';
import {ShippingOptionModel} from '../../../../domain/models/ShippingOption.model';
import {PickupInfoBox} from '../../PickupInfoBox/PickupInfoBox';
import {DeliveryMethodSectionDataHook} from '../DeliveryMethodSection';
import {TimeSlotSelector} from './TimeSlotSelector';

export enum DeliveryMethodPickerDataHook {
  sectionTitle = 'DeliveryMethodPicker.sectionTitle',
  shippingRadioGroup = 'DeliveryMethodPicker.shippingRadioGroup',
  shippingOption = 'DeliveryMethodPicker.shippingOption',
  shippingOptionPrice = 'DeliveryMethodPicker.shippingOptionPrice',
  pickupBox = 'DeliveryMethodPicker.pickupBox',
}

export const DeliveryMethodPicker = () => {
  const {
    deliveryMethodStore,
    checkoutStore: {checkout, isPickupFlow},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const {setShippingOption, isUpdatingShippingOption} = deliveryMethodStore;
  const shouldShowShippingSection = !!checkout.shippingOptions?.length && !isPickupFlow;
  const shouldShowPickupSection = !!checkout.pickupOptions?.length;
  const shouldShowSectionHeaders = shouldShowShippingSection && shouldShowPickupSection;
  const [selectedShippingOptionId, setSelectedShippingOptionId] = useState(checkout.selectedShippingOption?.code);

  const getSelectedRadioButton = () =>
    [...checkout.shippingOptions, ...checkout.pickupOptions].find((deliveryOption) =>
      deliveryOption.hasTimeSlots
        ? Object.values(deliveryOption.timeSlotDays!)
            .flatMap((_) => _)
            .some((option) => option.id === checkout.selectedShippingOption?.code)
        : deliveryOption.scheduledDeliveryOptions?.some(
            (option) => option.code === checkout.selectedShippingOption?.code
          )
    )?.code ?? checkout.selectedShippingOption?.code;

  const [selectedRadioButtonId, setSelectedRadioButtonId] = useState(getSelectedRadioButton);

  const onOptionSelected = (optionId: string): void => {
    setSelectedShippingOptionId(optionId);
    setSelectedRadioButtonId(optionId);
    void setShippingOption(optionId);
  };
  useEffect(
    () => {
      setSelectedRadioButtonId(getSelectedRadioButton());
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [checkout.selectedShippingOption?.code]
  );

  const onScheduledDeliverySelected = (optionId: string): void => {
    setSelectedShippingOptionId(optionId);
    void setShippingOption(optionId);
  };

  const getShippingSubtext = (option: ShippingOptionModel) => {
    return option.scheduledDeliveryOptions || option.hasTimeSlots ? undefined : option.deliveryTime;
  };

  const getPickupSubtext = ({pickupInfo}: ShippingOptionModel) => {
    return localeKeys.checkout.delivery_method.pickup_address({
      addressLine: pickupInfo?.address?.addressLine,
      city: pickupInfo?.address?.city,
      country: pickupInfo?.address?.countryFullname,
      subdivision: pickupInfo?.address?.subdivisionFullname,
      zipCode: pickupInfo?.address?.postalCode,
    });
  };

  return (
    <div data-hook={DeliveryMethodSectionDataHook.deliveryMethodPicker}>
      {shouldShowShippingSection && (
        <DeliveryMethodGroup
          title={localeKeys.checkout.deliveryMethod.shippingAndDelivery.label()}
          showHeader={shouldShowSectionHeaders}
          selectedId={selectedRadioButtonId}
          onSelected={(selectedId) => onOptionSelected(selectedId)}>
          {checkout.shippingOptions.map((option) => (
            <DeliveryMethod
              key={option.code}
              option={option}
              disabled={isUpdatingShippingOption}
              selectedId={selectedRadioButtonId}
              subtext={getShippingSubtext(option)}
              selectedShippingOptionId={selectedShippingOptionId}
              selectedDetailSection={() => {
                if (option.hasTimeSlots) {
                  return (
                    <TimeSlotSelector
                      timeSlotDays={option.timeSlotDays!}
                      selectedId={selectedShippingOptionId}
                      disabled={isUpdatingShippingOption}
                      onSelect={/* istanbul ignore next */ (optionId) => onScheduledDeliverySelected(optionId)}
                    />
                  );
                }
                if (option.scheduledDeliveryOptions) {
                  return (
                    <ScheduledDeliverySelector
                      disabled={isUpdatingShippingOption}
                      selectedId={selectedShippingOptionId}
                      scheduledDeliveryOptions={option.scheduledDeliveryOptions}
                      onSelect={(optionId) => onScheduledDeliverySelected(optionId)}
                    />
                  );
                }
              }}
            />
          ))}
        </DeliveryMethodGroup>
      )}

      {shouldShowPickupSection && (
        <DeliveryMethodGroup
          title={localeKeys.checkout.deliveryMethod.pickup.label()}
          showHeader={shouldShowSectionHeaders}
          selectedId={selectedRadioButtonId}
          onSelected={(selectedId) => onOptionSelected(selectedId)}>
          {checkout.pickupOptions.map((option) => (
            <DeliveryMethod
              key={option.code}
              option={option}
              disabled={isUpdatingShippingOption}
              selectedId={selectedRadioButtonId}
              subtext={getPickupSubtext(option)}
              selectedShippingOptionId={selectedShippingOptionId}
              selectedDetailSection={() => {
                if (option.hasTimeSlots) {
                  return (
                    <>
                      <TimeSlotSelector
                        timeSlotDays={option.timeSlotDays!}
                        selectedId={selectedShippingOptionId}
                        disabled={isUpdatingShippingOption}
                        onSelect={/* istanbul ignore next */ (optionId) => onScheduledDeliverySelected(optionId)}
                      />
                      <PickupInfoBox pickupInfo={option.pickupInfo} insideTimeSelector={true} />
                    </>
                  );
                }
                return <PickupInfoBox pickupInfo={option.pickupInfo} />;
              }}
            />
          ))}
        </DeliveryMethodGroup>
      )}
    </div>
  );
};
