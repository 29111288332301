import React, {useEffect, useState} from 'react';
import {Card, DatePickerInput, Dropdown, DropdownOptionProps, DropdownTheme} from 'wix-ui-tpa/cssVars';

import {TimeSlotOption} from '../../../../domain/models/ShippingOption.model';
import {classes} from './TimeSlotSelector.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';

export enum TimeSlotSelectorDataHook {
  root = 'TimeSlotSelectorDataHook.root',
  dropdown = 'TimeSlotSelectorDataHook.dropdown',
  timePickerDropdown = 'TimeSlotSelectorDataHook.timePickerDropdown',
}

export interface TimeSlotSelectorProps {
  timeSlotDays: {[dateString: string]: TimeSlotOption[]};
  selectedId?: string;
  disabled?: boolean;
  onSelect: (shippingOptionId: string) => void;
}

export const TimeSlotSelector = ({timeSlotDays, onSelect, disabled, selectedId}: TimeSlotSelectorProps) => {
  const localeKeys = useLocaleKeys();

  const findOptionById = () => {
    return Object.keys(timeSlotDays)
      .flatMap((day) => timeSlotDays[day])
      .find((option) => option.id === selectedId);
  };

  const [selectedOption, setSelectedOption] = useState(findOptionById);

  useEffect(() => setSelectedOption(findOptionById()), /* eslint-disable react-hooks/exhaustive-deps */ [selectedId]);

  return (
    <div className={classes.root}>
      <Card data-hook={TimeSlotSelectorDataHook.root} className={classes.dateTimePicker}>
        <DatePickerInput
          inputWidth={''}
          label={localeKeys.checkout.deliveryMethod.date.label()}
          className={classes.date}
          disabled={disabled}
          value={selectedOption?.date}
          onChange={
            /* istanbul ignore next */ (date) => {
              onSelect(timeSlotDays[new Date(date).toDateString()][0].id);
            }
          }
          filterDate={/* istanbul ignore next */ (date) => Boolean(timeSlotDays[date.toDateString()])}
          removeClearButton={true}
          data-hook={TimeSlotSelectorDataHook.dropdown}
        />
        <Dropdown
          data-hook={TimeSlotSelectorDataHook.timePickerDropdown}
          className={classes.time}
          label={localeKeys.checkout.deliveryMethod.time.label()}
          upgrade={true}
          theme={DropdownTheme.Box}
          disabled={disabled}
          initialSelectedId={selectedOption?.id}
          options={mapToTimePickerDropdownOptions(timeSlotDays[selectedOption!.date.toDateString()])}
          onChange={(option) => onSelect(option.id!)}
        />
      </Card>
    </div>
  );
};

const mapToTimePickerDropdownOptions = (t: TimeSlotOption[]): DropdownOptionProps[] => {
  return t.map((timeOption) => {
    return {
      id: timeOption.id,
      value: formatTime(timeOption.date),
      isSelectable: true,
    };
  });
};

const formatTime = (date: Date) => {
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};
