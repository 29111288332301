import {LocalDeliveryByZipCodeFlag, Payments, StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {Checkout} from '../../types/app.types';
import {CheckoutWithExtendedFieldsFragment, ExtendedFieldsFragment} from '../../gql/graphql';

export interface StoreCheckoutSettingsAndPayments {
  checkoutSettings: StoreCheckoutSettings;
  payments: Payments;
  localDeliveryByZipCodeFlag: LocalDeliveryByZipCodeFlag;
}

export interface UserFieldsNamespaces {
  _user_fields: any | null;
}

export type ExtendedFieldNamespaces = Pick<ExtendedFieldsFragment, 'namespaces'> | UserFieldsNamespaces;

export interface UserExtendedFields {
  namespaces?: ExtendedFieldNamespaces;
}

export function isExtendedFieldNamespacesIsUserFields(
  namespaces: ExtendedFieldNamespaces
): namespaces is UserFieldsNamespaces {
  return (namespaces as UserFieldsNamespaces)._user_fields !== undefined;
}

export function isCheckoutWithExtendedFields(checkout: Checkout): checkout is CheckoutWithExtendedFieldsFragment {
  return (checkout as CheckoutWithExtendedFieldsFragment).extendedFields !== undefined;
}
